let axios = require('axios').default
let HttpParams = require('../http/HttpParams.js')
let Preference = require('../data/Preference.js')
let Utils = require('../helpers/Utils.js')

let preference = new Preference()
let self
let authData

class HttpRequest {

    constructor() {
        self = this
        authData = preference.getProfileData()
    }

    setAuthorizationCallback(onSessionExpired) {
        self.onSessionExpired = onSessionExpired
    }

    // LogIn
    async login(email, password, client_id) {

        let responseData = self.initResponse()

        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_EMAIL, email)
        body.append(HttpParams.PARAM_PASSWORD, password)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_CLIENT_ID]: client_id,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.post(HttpParams.getLoginApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async passwordResetRequest(email, client_id) {
        let responseData = self.initResponse()

        let config = {
            params: {
                [HttpParams.PARAM_EMAIL]: email
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_CLIENT_ID]: client_id,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getPwdRequestApiApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async workspaceNameRequest(email, client_id) {
        let responseData = self.initResponse()

        let config = {
            params: {
                [HttpParams.PARAM_EMAIL]: email
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_CLIENT_ID]: client_id,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getWorkspaceNameApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    //member_request
    async orgMemberRequest(email, user_id) {
        let responseData = self.initResponse()

        let config = {
            params: {
                [HttpParams.PARAM_EMAIL]: email,
                [HttpParams.PARAM_USER_ID]: user_id,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            },
        }

        await axios.get(HttpParams.getMemberRequestApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async resendUserVerifyMail(email) {
        let responseData = self.initResponse()
        let config = {
            params: {
                [HttpParams.PARAM_EMAIL]: email
            }
        }

        await axios.get(HttpParams.getVerifyMailResendApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async registration(name, email, password, auth_type, work_space) {

        let responseData = self.initResponse()

        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_NAME, name)
        body.append(HttpParams.PARAM_EMAIL, email)
        body.append(HttpParams.PARAM_PASSWORD, password)
        body.append(HttpParams.PARAM_AUTH_TYPE, auth_type)
        body.append(HttpParams.PARAM_WORK_SPACE, work_space)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED
            }
        }

        await axios.post(HttpParams.getRegistrationApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })
        return responseData

    }
    // version number checked
    async getNewVersionNumber() {
        await self.getNewToken()
        let responseData = self.initResponse()
        let body = new URLSearchParams()
        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }
        await axios.post(HttpParams.getNewVersionNumberApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })
        return responseData
    }
    // current used version number
    async getCurrentVersionNumber() {
        await self.getNewToken()
        let responseData = self.initResponse()
        let body = new URLSearchParams()
        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }
        await axios.post(HttpParams.getCurrentVersionNumberApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })
        return responseData
    }
    async resetPassword(user_id, auth_data, client_id, token, password) {
        let responseData = self.initResponse()

        let config = {
            params: {
                [HttpParams.PARAM_USER_ID]: user_id,
                [HttpParams.PARAM_OLD_PASSWORD_HASH]: auth_data,
                [HttpParams.PARAM_NEW_PASSWORD]: password,
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_CLIENT_ID]: client_id,
                [HttpParams.PARAM_TOKEN]: token,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getResetPasswordApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })
        return responseData

    }

    async createOrganization(org_name, number_of_employee, user_id, org_address, accessPermission) {

        let responseData = self.initResponse()

        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_NAME, org_name)
        body.append(HttpParams.PARAM_NOE, number_of_employee)
        body.append(HttpParams.PARAM_ADDRESS, org_address)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: user_id,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getAddOrganizationApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async editOrganization(org_name, number_of_employee, user_id, org_address, org_id, accessPermission) {
        // Initialize the response data
        let responseData = self.initResponse();

        // Create a new URLSearchParams object to encode the request body
        let body = new URLSearchParams();
        body.append(HttpParams.PARAM_NAME, org_name);
        body.append(HttpParams.PARAM_ADDRESS, org_address);
        body.append(HttpParams.PARAM_ORGANIZATION_ID, org_id);
        body.append(HttpParams.PARAM_NOE, number_of_employee);

        // Define the configuration for the HTTP request
        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: user_id,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        };

        try {
            // Make an asynchronous POST request using Axios
            const response = await axios.post(HttpParams.getEditOrganizationApi(), body, config);

            // Build a success response based on the Axios response
            responseData = self.buildSuccessResponse(response);
        } catch (error) {
            // Build a failed response based on the error
            responseData = self.buildFailedResponse(error);
        }

        // Return the response data
        return responseData;
    }


    async getOrganizationList(accessPermission) {
        await self.getNewToken()
        let responseData = self.initResponse()
        let body = new URLSearchParams()

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getOrganizationListApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }
    async getProjectsTasks(memberEmail, accessPermission) {
        // Asynchronously obtain a new access token
        await self.getNewToken();

        // Initialize response data object
        let responseData = self.initResponse();

        // Create URLSearchParams object for the request body
        let body = new URLSearchParams();

        // Configure HTTP request headers and parameters
        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_MEMBER_EMAIL]: memberEmail,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        };
        // Perform a POST request to retrieve projects and tasks
        await axios.post(HttpParams.getProjectsTasksApi(), body, config)
            .then(function (response) {
                // Build a success response using the received data
                responseData = self.buildSuccessResponse(response);
            })
            .catch(function (error) {
                // Build a failed response in case of an error
                responseData = self.buildFailedResponse(error);
            });

        // Return the obtained response data
        return responseData;
    }



    async getWorkSpaceMembersList() {
        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.post(HttpParams.getWorkSpaceMembersListApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async addMemberToOrganization(org_id, email, name, role_id, accessPermission) {
        await self.getNewToken()
        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_ORGANIZATION_ID, org_id)
        body.append(HttpParams.PARAM_EMAIL, email)
        body.append(HttpParams.PARAM_NAME, name)
        body.append(HttpParams.PARAM_ROLE_ID, role_id)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getOrganizationAddMemberApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }


    async inviteMemberToDepartment(
        selectedDepartmentForInvite,
        selectedDepartmentRoleForInvite,
        selectedProjectForInvite,
        selectedProjectRoleForInvite,
        otherRoleValue,
        memberUserEmail,
        memberUserName,
        accessPermission
    ) {
        // Get a new access token before making the API call
        await self.getNewToken();

        // Initialize response data object
        let responseData = self.initResponse();

        // Create a URLSearchParams object with the request parameters
        let body = new URLSearchParams();
        body.append(HttpParams.PARAM_ORGANIZATION_ID, selectedDepartmentForInvite);
        body.append(HttpParams.PARAM_ORGANIZATION_ROLE_ID, selectedDepartmentRoleForInvite);
        body.append(HttpParams.PARAM_PROJECT_ID, selectedProjectForInvite);
        body.append(HttpParams.PARAM_PROJECT_ROLE_ID, selectedProjectRoleForInvite);
        body.append(HttpParams.PARAM_OTHER_ROLE_VALUE, otherRoleValue);
        body.append(HttpParams.PARAM_EMAIL, memberUserEmail);
        body.append(HttpParams.PARAM_NAME, memberUserName);

        // Configure request headers
        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            },
        };

        // Make a POST request to the inviteMemberToDepartment API
        await axios
            .post(HttpParams.inviteMemberToDepartmentApi(), body, config)
            .then(function (response) {
                // Build success response
                responseData = self.buildSuccessResponse(response);
            })
            .catch(function (error) {
                // Build failed response
                responseData = self.buildFailedResponse(error);
            });

        // Return the response data
        return responseData;
    }


    async updateOrganizationMember(org_id, email, name, role_id, accessPermission) {
        await self.getNewToken()
        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_ORGANIZATION_ID, org_id)
        body.append(HttpParams.PARAM_EMAIL, email)
        body.append(HttpParams.PARAM_NAME, name)
        body.append(HttpParams.PARAM_ROLE_ID, role_id)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.patch(HttpParams.getOrganizationUpdateMemberApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async deleteMemberFromOrganization(org_id, email, accessPermission) {
        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_ORGANIZATION_ID, org_id)
        body.append(HttpParams.PARAM_EMAIL, email)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getOrganizationDeleteMemberApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async ActiveMemberToOrganization(email, accessPermission) {
        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_EMAIL, email)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getOrganizationActiveMemberApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async getOrganizationMembers(organization_id) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_ORGANIZATION_ID, organization_id)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.post(HttpParams.getOrganizationMemberListApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async getMembersDetails() {
        await self.getNewToken()
        let responseData = self.initResponse()
        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getMembersDetailsApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async getMembersRequestsDetails(timezone) {
        await self.getNewToken()
        let responseData = self.initResponse()
        
        let config = {
            params: {
               
                [HttpParams.PARAM_TIMEZONE]: timezone
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getMembersRequestsDetailsApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    // Operation on project
    async getProjectTypes() {
        await self.getNewToken()
        let responseData = self.initResponse()

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getProjectTypeApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async getProjectList(accessPermission) {

        await self.getNewToken()
        let responseData = self.initResponse()

        let body = new URLSearchParams()

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getAllProjectListApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async addProject(project_name, project_description, org_id, project_type_id, accessPermission) {
        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_NAME, project_name)
        body.append(HttpParams.PARAM_DESCRIPTION, project_description)
        body.append(HttpParams.PARAM_ORGANIZATION_ID, org_id)
        body.append(HttpParams.PARAM_PROJECT_TYPE_ID, project_type_id)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getProjectAddApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }


    async approveThisUserEvent(whatIsRequestType, event_id, request_user_id , jira_issue_key, meeting_time, event_note, accessPermission) {
        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append('request_type', whatIsRequestType)
        body.append('request_event_id', event_id)
        body.append('requested_user_id', request_user_id)
        body.append('jira_issue_key', jira_issue_key)
        body.append('meeting_time', meeting_time)
        body.append('event_note', event_note)






        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.approveThisUserEventApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async editProject(project_name, project_description, org_id, project_type_id, project_id, project_status, accessPermission) {
        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_NAME, project_name)
        body.append(HttpParams.PARAM_DESCRIPTION, project_description)
        body.append(HttpParams.PARAM_ORGANIZATION_ID, org_id)
        body.append(HttpParams.PARAM_PROJECT_TYPE_ID, project_type_id)
        body.append(HttpParams.PARAM_PROJECT_ID, project_id)
        body.append(HttpParams.PARAM_PROJECT_STATUS, project_status)
        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getProjectEditApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async addTask(task_title, task_description, task_origin_url, project_id, project_memberId, accessPermission) {
        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_TITLE, task_title)
        body.append(HttpParams.PARAM_DESCRIPTION, task_description)
        body.append(HttpParams.PARAM_TASK_ORIGIN_URL, task_origin_url)
        body.append(HttpParams.PARAM_PROJECT_ID, project_id)
        body.append(HttpParams.PARAM_PROJECT_MEMBER_ID, project_memberId)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getTaskAddApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async editTask(task_title, task_description, task_origin_url, project_id, task_id, project_member_id, task_status, accessPermission) {
        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_TITLE, task_title)
        body.append(HttpParams.PARAM_DESCRIPTION, task_description)
        body.append(HttpParams.PARAM_TASK_ORIGIN_URL, task_origin_url)
        body.append(HttpParams.PARAM_PROJECT_ID, project_id)
        body.append(HttpParams.PARAM_TASK_ID, task_id)
        body.append(HttpParams.PARAM_PROJECT_MEMBER_ID, project_member_id)
        body.append(HttpParams.PARAM_TASK_STATUS, task_status)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getTaskEditApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async addMemberToProject(project_id, email, role_id, accessPermission) {
        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_PROJECT_ID, project_id)
        body.append(HttpParams.PARAM_EMAIL, email)
        body.append(HttpParams.PARAM_ROLE_ID, role_id)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getProjectAddMemberApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async addBulkMembersToProject(project_id, emails, role_id, accessPermission) {
        await self.getNewToken()
        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_PROJECT_ID, project_id)
        body.append(HttpParams.PARAM_EMAIL, emails)
        body.append(HttpParams.PARAM_ROLE_ID, role_id)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getProjectAddBulkMembersApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async deleteMemberFromProject(project_id, email, accessPermission) {
        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_PROJECT_ID, project_id)
        body.append(HttpParams.PARAM_EMAIL, email)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getProjectDeleteMemberApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async deleteProject(project_id, accessPermission) {
        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_PROJECT_ID, project_id)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }

        await axios.post(HttpParams.getProjectDeleteApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async deleteDepartment(orgID, accessPermission) {
        await self.getNewToken()
        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_ORGANIZATION_ID, orgID)
        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_ACCESS_PERMISSION]: accessPermission
            }
        }
        await axios.post(HttpParams.getDepartmentDeleteApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })
        return responseData
    }
    async getRole(role_type) {

        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_ROLE_TYPE, role_type)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.post(HttpParams.getGlobalRoleApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }
    async getAllRole() {

        await self.getNewToken()

        let responseData = self.initResponse()
        let body = new URLSearchParams()

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.post(HttpParams.getAllRoleRoleApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async getProjectMembers(project_id) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_PROJECT_ID, project_id)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.post(HttpParams.getProjectMemberListApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async getTaskList(project_id) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_PROJECT_ID, project_id)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.post(HttpParams.getTaskListApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async getSummary(fromDate, toDate, scope) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let config = {
            params: {
                [HttpParams.PARAM_FROM_DATE]: fromDate,
                [HttpParams.PARAM_TO_DATE]: toDate,
                [HttpParams.PARAM_SCOPE]: scope
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getSummaryApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async getRecent(fromDate, toDate, scope) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let config = {
            params: {
                [HttpParams.PARAM_FROM_DATE]: fromDate,
                [HttpParams.PARAM_TO_DATE]: toDate,
                [HttpParams.PARAM_SCOPE]: scope
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getRecentApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async getActivityChart(fromDate, toDate, scope) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let config = {
            params: {
                [HttpParams.PARAM_FROM_DATE]: fromDate,
                [HttpParams.PARAM_TO_DATE]: toDate,
                [HttpParams.PARAM_SCOPE]: scope
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getActivityChartApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }
    async getDashboardTopUsers(order) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let config = {
            params: {
                [HttpParams.PARAM_DATA_ORDER]: order

            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getDashboardTopUsersApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async getDashboardProjects(fromDate, toDate, timeZone, scope) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let config = {
            params: {
                [HttpParams.PARAM_FROM_DATE]: fromDate,
                [HttpParams.PARAM_TO_DATE]: toDate,
                [HttpParams.PARAM_SCOPE]: scope,
                [HttpParams.PARAM_TIMEZONE]: timeZone
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getDashboardProjectsApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async getDashboardApps(fromDate, toDate, timeZone, scope) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let config = {
            params: {
                [HttpParams.PARAM_FROM_DATE]: fromDate,
                [HttpParams.PARAM_TO_DATE]: toDate,
                [HttpParams.PARAM_SCOPE]: scope,
                [HttpParams.PARAM_TIMEZONE]: timeZone
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getDashboardAppsApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }
    async getDashboardAppsUser(fromDate, toDate, timeZone, app_name, scope) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let config = {
            params: {
                [HttpParams.PARAM_FROM_DATE]: fromDate,
                [HttpParams.PARAM_TO_DATE]: toDate,
                [HttpParams.PARAM_SCOPE]: scope,
                [HttpParams.PARAM_TIMEZONE]: timeZone,
                [HttpParams.PARAM_APPNAME]: app_name
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getDashboardAppsUserApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async generateImageUrl(imageName) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let config = {
            params: {
                [HttpParams.PARAM_FILE_NAME]: imageName
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getSignUrlApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async getDashboardActivities(fromDate, toDate, timeZone, projectId, selectedUserId) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let params = {
            [HttpParams.PARAM_FROM_DATE]: fromDate,
            [HttpParams.PARAM_TO_DATE]: toDate,
            [HttpParams.PARAM_TIMEZONE]: timeZone
        }
        if (projectId != null) {
            Object.assign(params, { [HttpParams.PARAM_PROJECT_ID]: projectId });
        }
        if (selectedUserId != null) {
            Object.assign(params, { [HttpParams.PARAM_SELECTED_USER_ID]: selectedUserId });
        }

        let config = {
            params: params,
            headers: {
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getDashboardActivityApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async getDashboardWindowLog(fromDate, toDate, projectId, selectedUserId, timeZone) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let params = {
            [HttpParams.PARAM_FROM_DATE]: fromDate,
            [HttpParams.PARAM_TO_DATE]: toDate,
            [HttpParams.PARAM_TIMEZONE]: timeZone
        }
        if (projectId != null) {
            Object.assign(params, { [HttpParams.PARAM_PROJECT_ID]: projectId });
        }
        if (selectedUserId != null) {
            Object.assign(params, { [HttpParams.PARAM_SELECTED_USER_ID]: selectedUserId });
        }

        let config = {
            params: params,
            headers: {
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getDashboardWindowLogApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async getReportPreparationData(fromDate, toDate, projectId, selectedUserId, timezone) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let params = {
            [HttpParams.PARAM_FROM_DATE]: fromDate,
            [HttpParams.PARAM_TO_DATE]: toDate,
            [HttpParams.PARAM_TIMEZONE]: timezone
        }
        if (projectId != null) {
            Object.assign(params, { [HttpParams.PARAM_PROJECT_ID]: projectId });
        }
        if (selectedUserId != null) {
            Object.assign(params, { [HttpParams.PARAM_SELECTED_USER_ID]: selectedUserId });
        }

        let config = {
            params: params,
            headers: {
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getReportPreparationData(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData

    }

    async deleteFile(fileName) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_FILE_NAME, fileName)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.post(HttpParams.getActivityDeleteFileApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async initJira() {

        await self.getNewToken()

        let responseData = self.initResponse()


        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getJiraInitApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async addJiraConfig(jiraURL, consumerKey, privateKey, jira_api_version) {

        await self.getNewToken()

        let responseData = self.initResponse()

        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_JIRA_URL, jiraURL)
        body.append(HttpParams.PARAM_CONSUMER_KEY, consumerKey)
        body.append(HttpParams.PARAM_PRIVATE_KEY, privateKey)
        body.append(HttpParams.PARAM_JIRA_API_VERSION, jira_api_version)
        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.post(HttpParams.getJiraConfigApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }


    async addScrrenShotTimeIntervalConfigs(screenshot_time_interval) {

        await self.getNewToken()

        let responseData = self.initResponse()

        let body = new URLSearchParams()

        body.append(HttpParams.PARAM_SCREENSHOT_TIME_INTERVAL, screenshot_time_interval)
        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.post(HttpParams.getScrrenShotTimeIntervalConfigsApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async getJiraConfig() {

        await self.getNewToken()

        let responseData = self.initResponse()

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getJiraConfigApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }


    async getConfigs() {

        await self.getNewToken()

        let responseData = self.initResponse()

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getConfigApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async requestTokenForJiraIntegration(userId, accessToken, accessTokenSecret, oathVerifier) {

        await self.getNewToken()

        let responseData = self.initResponse()


        let config = {
            params: {
                [HttpParams.PARAM_USER_ID]: userId,
                [HttpParams.PARAM_JIRA_TOKEN]: accessToken,
                [HttpParams.PARAM_JIRA_TOKEN_SECRET]: accessTokenSecret,
                [HttpParams.PARAM_JIRA_OAUTH_VERIFIER]: oathVerifier
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getJiraConnectionCallbackApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async getIntegrationVendors(userId) {

        await self.getNewToken()

        let responseData = self.initResponse()


        let config = {
            params: {
                [HttpParams.PARAM_USER_ID]: userId,
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getIntegrationVendors(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async synJira(userId, jiraHostUrl, jira_api_version) {

        await self.getNewToken()

        let responseData = self.initResponse()


        let config = {
            params: {
                [HttpParams.PARAM_USER_ID]: userId,
                [HttpParams.PARAM_JIRA_HOST_URL]: jiraHostUrl
            },
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin,
                [HttpParams.PARAM_JIRA_API_VERSION]: jira_api_version,
            }
        }

        await axios.get(HttpParams.getJiraSyncApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async editActivityData(activityId, comment, type) {
        await self.getNewToken()

        let responseData = self.initResponse()

        let body = new URLSearchParams()
        body.append(HttpParams.PARAM_ACTIVITY_ID, activityId)
        body.append(HttpParams.PARAM_COMMENT, comment)
        body.append(HttpParams.PARAM_ACTIVITY_TYPE, type)

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.post(HttpParams.getActivityEditApi(), body, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }

    async getNewToken() {
        authData = preference.getProfileData()
        let responseData = self.initResponse()

        if (Utils.isTokenExpired(authData.accessTokenExpiresAt)) {

            let body = new URLSearchParams()

            let config = {
                headers: {
                    [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                    [HttpParams.PARAM_USER_ID]: authData.userId,
                    [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.refreshToken,
                    [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                    [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
                }
            }
            await axios.post(HttpParams.getTokenApi(), body, config).then(function (response) {
                responseData = self.buildSuccessResponse(response)
                preference.setAuthData(response.data.data)
                authData = preference.getProfileData()
            }).catch(function (error) {
                self.onSessionExpired()
                responseData = self.buildFailedResponse(error)
            })
        } else {
            responseData.success = true
        }
        return responseData

    }

    async getWorkspaceDetails() {
        // Asynchronously obtain a new access token
        await self.getNewToken();
        // Initialize response data object
        let responseData = self.initResponse();
        // Configure HTTP request headers
        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        };
        // Perform a GET request to retrieve workspace details
        await axios.get(HttpParams.getWorkspaceApi(), config)
            .then(function (response) {
                // Build a success response using the received data
                responseData = self.buildSuccessResponse(response);
            })
            .catch(function (error) {
                // Build a failed response in case of an error
                responseData = self.buildFailedResponse(error);
            });
        // Return the obtained response data
        return responseData;
    }


    async getMyProfileData() {

        await self.getNewToken()
        let responseData = self.initResponse()

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.get(HttpParams.getProfileApi(), config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }


    async updateWorkspaceDetails(workSpaceId, companyName, companyEmail, companyLogo, subscriptionId) {
        await self.getNewToken()
        let responseData = self.initResponse()
        let formData = new FormData()
        if (companyName) {
            formData.append(HttpParams.PARAM_COMPANY_NAME, companyName)
        }
        if (companyEmail) {
            formData.append(HttpParams.PARAM_COMPANY_EMAIL, companyEmail)
        }
        if (companyLogo) {
            formData.append(HttpParams.PARAM_COMPANY_LOGO, companyLogo)
        }
        if (subscriptionId) {
            formData.append(HttpParams.PARAM_SUBSCRIPTION_ID, subscriptionId)
        }

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.patch(HttpParams.getWorkspaceUpdateApi(workSpaceId), formData, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }


    async updateProfileData(name, password, avatar) {
        await self.getNewToken()
        let responseData = self.initResponse()
        let formData = new FormData()
        if (name) {
            formData.append(HttpParams.PARAM_NAME, name)
        }
        if (password) {
            formData.append(HttpParams.PARAM_PASSWORD, password)
        }
        if (avatar) {
            formData.append(HttpParams.PARAM_IMAGE, avatar)
        }

        let config = {
            headers: {
                [HttpParams.PARAM_CONTENT_TYPE]: HttpParams.URL_ENCODED,
                [HttpParams.PARAM_USER_ID]: authData.userId,
                [HttpParams.PARAM_AUTHORIZATION]: [HttpParams.BEARER] + authData.accessToken,
                [HttpParams.PARAM_CLIENT_ID]: authData.clientId,
                [HttpParams.PARAM_ORIGIN_URL]: window.location.origin
            }
        }

        await axios.patch(HttpParams.getProfileApi(), formData, config).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }


    async getWorkspaceData() {

        let responseData = self.initResponse()

        await axios.get(HttpParams.getWorkspace(window.location.origin)).then(function (response) {
            responseData = self.buildSuccessResponse(response)
        }).catch(function (error) {
            responseData = self.buildFailedResponse(error)
        })

        return responseData
    }


    initResponse() {
        return {
            success: false,
            message: 'Failed',
            data: ''
        }
    }

    buildSuccessResponse(data) {
        return {
            success: true,
            message: data.data.message,
            data: data.data.data
        }
    }

    buildFailedResponse(error) {
        if (error == null || error.response == null) {
            return self.initResponse()
        } else {
            return {
                success: false,
                message: error.response.data.message,
                data: error.response.data.data
            }
        }
    }

}

module.exports = HttpRequest
